<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code</label>
            <b-form-input
              size="lg"
              v-model="filter.id_project"
              placeholder="Project Code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Letter Number</label>
            <b-form-input
              size="lg"
              placeholder="Letter Number"
              v-model="filter.letter_number"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>
            <b-form-input
              size="lg"
              placeholder="Nickname"
              v-model="filter.nickname"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Subject</label>
            <b-form-input
              size="lg"
              placeholder="Subject"
              v-model="filter.subject"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Letter Date</label>
            <b-input-group>
              <input
                class="form-control"
                type="text"
                v-model="filter.letter_date"
                id="example-date-input"
                placeholder="YYYY-MM-DD"
              />
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  v-model="filter.letter_date"
                  :no-flip="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="id"
                  reset-button
                  reset-value=""
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="statusOptions"
              size="lg"
            ></b-form-select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12">
            <div class="text-right">
              <button class="btn btn-primary btn-md" @click="onSearch">
                <i class="flaticon2-search-1"></i> Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="d-flex my-3 justify-content-end">
        <button class="btn btn-success btn-md" @click="showAddModal">
          <i class="flaticon-add"></i>
        </button>
      </div>

      <div class="card-body p-0">
        <div class="table-responsive">
          <complete-table :loading="loading" :data="this.currentLettering">
            <template #header>
              <tr class="text-left">
                <th style="min-width: 100px" class="pl-7">
                  <span class="text-dark-75">Nickname</span>
                </th>
                <th style="min-width: 50px">Type</th>
                <th style="min-width: 150px">Letter Date</th>
                <th style="min-width: 80px">Letter Number</th>
                <th style="min-width: 80px">Subject</th>
                <th style="min-width: 80px">Status</th>
                <th style="min-width: 80px">PIC</th>
                <th style="min-width: 80px">From</th>
                <th style="min-width: 80px">To</th>
                <th style="min-width: 150px">Follow Up</th>
                <th style="min-width: 80px">File</th>

                <th style="min-width: 150px">Action</th>
              </tr>
            </template>

            <!-- <template v-for="(item, i) in this.currentLettering"> -->
            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.project.nickname }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.letter_type }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{
                      moment(item.date).isValid() ?
                      moment(item.date).format("DD-MM-YYYY") : "" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.code }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.subject }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.status }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.pic_code }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.from }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.to }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.follow_up == 1 ? "YES" : "NO" }}
                  </span>
                </td>

                <td>
                  <span class="text-dark-75 font-weight-bolder font-size-lg">
                    <template v-if="item.file !== null ? true : false">
                      <a href="#" @click="onDownload(item.file)"
                        >{{
                          item.file !== null ? item.file.split("/").pop() : ""
                        }}
                      </a>
                    </template>
                  </span>
                </td>

                <td class="pr-0">
                  <a
                    class="btn btn-light-success font-weight-bolder font-size-sm ml-2"
                    @click="showEditModal(item)"
                  >
                    <i class="flaticon-edit"></i>
                  </a>

                  <a
                    class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                    @click="showDeleteModal(item)"
                  >
                    <i class="flaticon-delete"></i>
                  </a>
                </td>
              </tr>
            </template>
          </complete-table>
        </div>
      </div>
    </div>

    <b-modal ref="add-letter" hide-footer title="Form Add Letter" size="lg">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Letter Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Letter Number"
              v-model="form.code"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Project Code</label>
            <b-form-select
              id="input-lg"
              value-field="nickname"
              text-field="nickname"
              size="lg"
              v-model="form.project"
            >
              <b-form-select-option
                v-for="(proj, i) in this.projectList"
                v-bind:key="i"
                :value="{ code: proj.code, text: proj.nickname }"
              >
                {{ proj.nickname }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">PIC</label>

            <b-form-select
              v-model="form.pic_code"
              :options="options.pic"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Type</label>

            <b-form-select
              v-model="form.letter_type"
              :options="options.letter_type"
              size="lg"
            ></b-form-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">From</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="From"
              v-model="form.from"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">To</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="To"
              v-model="form.to"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Letter Date</label>
            <b-input-group>
              <input
                class="form-control"
                type="text"
                v-model="form.date"
                id="example-date-input"
                placeholder="YYYY-MM-DD"
              />
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  v-model="form.date"
                  locale="id"
                  reset-button
                  reset-value=""
                  :no-flip="true"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Subject</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Letter Subject"
              v-model="form.subject"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Follow Up</label>

            <b-form-select
              v-model="form.follow_up"
              :options="options.followUp"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">File</label>

            <b-form-file
              v-model="form.file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="selectedFile($event)"
            ></b-form-file>
          </div>

          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="form.status"
              :options="statusOptions"
              size="lg"
            ></b-form-select>
          </div>
        </div>
      </div>

      <div class="d-flex mt-2">
        <b-button
          ref="kt_simpan_lettering"
          class="btn btn-success mr-2"
          block
          @click="onSave"
          >Save</b-button
        >

        <b-button class="btn btn-secondary mt-0" block @click="closeAddModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>

    <b-modal
      ref="edit-letter"
      hide-footer
      title="Form Updatet Letter"
      size="lg"
    >
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Letter Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Letter Number"
              v-model="form.code"
              readonly
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Project Code</label>
            <b-form-select
              id="input-lg"
              value-field="nickname"
              text-field="nickname"
              size="lg"
              v-model="form.project"
            >
              <b-form-select-option
                v-for="(proj, i) in this.projectList"
                v-bind:key="i"
                :value="{ code: proj.code, text: proj.nickname }"
              >
                {{ proj.nickname }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">PIC</label>

            <b-form-select
              v-model="form.pic_code"
              :options="options.pic"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Type</label>

            <b-form-select
              v-model="form.letter_type"
              :options="options.letter_type"
              size="lg"
            ></b-form-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">From</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="From"
              v-model="form.from"
            ></b-form-input>
          </div>
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">To</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="To"
              v-model="form.to"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Letter Date</label>
            <b-input-group>
              <input
                class="form-control"
                type="text"
                v-model="form.date"
                id="example-date-input"
                placeholder="YYYY-MM-DD"
              />
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  v-model="form.date"
                  locale="id"
                  reset-button
                  reset-value=""
                  :no-flip="true"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Subject</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Letter Subject"
              v-model="form.subject"
            ></b-form-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Follow Up</label>

            <b-form-select
              v-model="form.follow_up"
              :options="options.followUp"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">File</label>

            <b-form-file
              v-model="form.file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="selectedFile($event)"
            ></b-form-file>
          </div>

          <div class="col-md-6 py-2 col-sm-12 text-left">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="form.status"
              :options="statusOptions"
              size="lg"
            ></b-form-select>
          </div>
        </div>
      </div>

      <div class="d-flex mt-2">
        <b-button
          ref="kt_update_lettering"
          class="btn btn-success mr-2"
          block
          @click="onUpdate"
          >Update</b-button
        >

        <b-button class="btn btn-secondary mt-0" block @click="closeEditModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>

    <b-modal ref="delete-letter" hide-footer title="Hapus Letter">
      <div class="d-block text-center mb-5 pb-5 pt-5">
        Are You Sure Want To Delete Letter ?
      </div>

      <div class="d-flex mt-5">
        <b-button
          class="btn btn-success mr-2"
          block
          @click="onDelete"
          ref="kt_delete_lettering"
          >Delete</b-button
        >

        <b-button class="btn btn-secondary mt-0" block @click="closeDeleteModal"
          >Cancel</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_LETTERING,
  POST_LETTERING,
  PUT_LETTERING,
  POST_UPLOAD_ATTACHMENT_LETTER,
  POST_DOWNLOAD_ATTACHMENT_LETTER,
  DELETE_LETTERING,
} from "@/core/services/store/lettering.module";
import { mapGetters, mapState } from "vuex";
import { GET_PIC } from "@/core/services/store/pic.module";
import { showToast } from "@/core/helper/toast.helper";
import {
  SHOW_PROJECT_DESIGN,
  SHOW_PROJECT_INTERFACE,
  SHOW_PROJECT_ADMIN,
} from "../../../core/services/store/dl.design.module";
import CompleteTable from "../../../view/content/widgets/advance-table/CompleteTable.vue";

export default {
  components: { CompleteTable },
  name: "lettering",
  data() {
    return {
      statusOptions: [
        {
          text: "Choose Status",
          value: null,
        },
        {
          text: "Open",
          value: "open",
        },
        {
          text: "Close",
          value: "close",
        },
      ],
      filter: {
        nickname: null,
        letter_date: null,
        letter_number: null,
        subject: null,
        id_project: null,
        status: null,
      },
      selectedFiles: undefined,

      data: {
        letter: {
          code: null,
          project: {
            code: null,
            name: null,
            nickname: null,
          },
          letter_type: null,
          date: null,
          subject: null,
          pic: {
            code: null,
            name: null,
          },
          from: null,
          to: null,
          status: null,
          follow_up: null,
        },
      },
      options: {
        followUp: [
          { value: 1, text: "YES" },
          { value: 0, text: "NO" },
        ],
        letter_type: [
          { value: "in", text: "In" },
          { value: "out", text: "Out" },
          { value: "reply", text: "Reply" },
        ],
        project: [{ value: null, text: null }],
        pic: [{ value: null, text: null }],
      },
      form: {
        code: null,
        id_project: null,
        letter_type: null,
        date: null,
        subject: null,
        pic_code: null,
        from: null,
        to: null,
        follow_up: null,
        status: null,
      },
    };
  },
  methods: {
    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },
    showAddModal() {
      this.form = {
        code: null,
        id_project: null,
        letter_type: null,
        date: null,
        subject: "",
        pic_code: null,
        from: "",
        to: "",
        follow_up: null,
      };

      this.$refs["add-letter"].show();
    },

    closeAddModal() {
      this.$refs["add-letter"].hide();
    },

    addletter() {
      this.data.roles.push(this.form);

      this.closeAddModal();
    },

    showEditModal(letter) {
      Object.assign(this.form, {
        code: letter.code,
        id_project: letter.project_code,
        letter_type: letter.letter_type,
        date: letter.date,
        subject: letter.subject,
        pic_code: letter.pic_code,
        from: letter.from,
        to: letter.to,
        follow_up: letter.follow_up,
        status: letter.status,
        project: {
          code: letter.project.code,
          text: letter.project.nickname,
        },
      });

      this.$refs["edit-letter"].show();
    },

    closeEditModal() {
      this.$refs["edit-letter"].hide();
    },

    showDeleteModal(letter) {
      Object.assign(this.form, {
        code: letter.code,
      });

      this.$refs["delete-letter"].show();
    },

    closeDeleteModal() {
      this.$refs["delete-letter"].hide();
    },

    deleteletter() {
      //
    },

    mappingDataProject(projectList) {
      this.options.project = [];
      projectList.map((project) =>
        this.options.project.push({
          text: project.code + "-" + project.nickname,
          value: project.code,
        })
      );
    },
    mappingDataPic(listpic) {
      this.options.pic = [];
      listpic.map((pic) =>
        this.options.pic.push({
          text: pic.code + "-" + pic.name,
          value: pic.code,
        })
      );
    },

    onSearch() {
      console.log("search");
      this.$store.dispatch(GET_LETTERING, { params: this.filter });
    },

    mappingFormToObject(form) {
      console.log(form);
      Object.assign(this.data.letter, {
        code: form.code,
        project: {
          code: form.project.code,
          name: null,
          nickname: form.project.text,
        },
        letter_type: form.letter_type,
        date: form.date,
        subject: form.subject,
        pic: {
          code: form.pic_code,
          name: null,
        },
        follow_up: form.follow_up,
        from: form.from,
        to: form.to,
        status: form.status,
      });
    },

    selectedFile(event) {
      this.selectedFiles = event.target.files[0];
      console.log(this.selectedFiles);
    },

    onDownload(path) {
      console.log("path", path);
      if (path !== "") {
        this.$store
          .dispatch(POST_DOWNLOAD_ATTACHMENT_LETTER, { file_url: path })
          .then(() => {
            showToast("Notification", "Download Attachment Success", "success");
          })
          .catch((error) =>
            showToast("Notification", error.data.message, "danger")
          );
      }
    },

    UploadAttachment(leter_code, files) {
      if (files !== undefined) {
        let formData = new FormData();
        formData.append("file", files);
        formData.append("letter_code", leter_code);
        this.$store
          .dispatch(POST_UPLOAD_ATTACHMENT_LETTER, formData)
          .then(() => {
            showToast(
              "Notification",
              "Upload Attachment Saved Successfully",
              "success"
            );
            this.onSearch();
          })
          .catch((error) =>
            showToast("Notification", error.data.message, "danger")
          );
      }
    },

    onSave() {
      this.mappingFormToObject(this.form);
      const submitButton = this.$refs["kt_simpan_lettering"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        this.$store
          .dispatch(POST_LETTERING, this.data.letter)
          .then(() => {
            showToast("Notification", "Data Saved Successfully", "success");
            this.UploadAttachment(this.form.code, this.selectedFiles);
            this.closeAddModal();
          })
          .catch((error) =>
            showToast("Notifikasi", error.data.message, "danger")
          );

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },

    onDelete() {
      const submitButton = this.$refs["kt_delete_lettering"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        this.$store
          .dispatch(DELETE_LETTERING, { params: this.form })
          .then(() => {
            this.$store.dispatch(GET_LETTERING, { params: this.filter });
            this.closeDeleteModal();

            this.$store.dispatch(GET_LETTERING, { params: this.filter });
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },

    onUpdate() {
      this.mappingFormToObject(this.form);
      const submitButton = this.$refs["kt_update_lettering"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        this.$store
          .dispatch(PUT_LETTERING, this.data.letter)
          .then(() => {
            showToast("Notification", "Data Saved Successfully", "success");
            this.UploadAttachment(this.form.code, this.selectedFiles);
            this.closeEditModal();
            this.$store.dispatch(GET_LETTERING, { params: this.filter });
          })
          .catch((error) =>
            showToast("Notification", error.data.message, "danger")
          );

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    formatter(value) {
      let date = new Date(value);
      return (
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2)
      );
    },
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Lettering" }]);
    await this.$store.dispatch(GET_LETTERING, { params: this.filter });
    await this.$store
      .dispatch(GET_PIC)
      .then(() => this.mappingDataPic(this.currentPics));

    await this.$store.dispatch(SHOW_PROJECT_ADMIN);
    await this.$store.dispatch(SHOW_PROJECT_DESIGN);
    await this.$store.dispatch(SHOW_PROJECT_INTERFACE);
  },
  computed: {
    ...mapGetters(["currentLettering", "currentPics", "currentProjectDetails"]),
    ...mapState({
      loading: (state) => state.lettering.loading,
    }),

    projectList() {
      let projectAdmin = this.$store.getters.getterProjectAdmin;
      let projectDesign = this.$store.getters.getterProjectDesign;
      let projectInterface = this.$store.getters.getterProjectInterface;
      let combine1 = this.merge(projectAdmin, projectDesign, "code");

      return this.merge(combine1, projectInterface, "code");
    },
  },
};
</script>

<style></style>
